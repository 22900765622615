<template>
  <b-card no-body>
    <b-overlay :show="loading">
      <b-card-body>
        <b-card-title>
          <b-row>
            <b-col><div>Transaksi</div></b-col>
            <b-col>
              <div align="right"></div>
              <b-modal
                v-model="Modal"
                id="modal-tambah"
                centered
                size="sm"
                :title="titleModal"
              >
                <b-card-text>
                  <b-form>
                    <b-form-group label="Transaksi" label-for="kategori">
                      <b-form-input
                        id="kategori"
                        v-model="form.name"
                        placeholder="Transaksi"
                      />
                    </b-form-group>
                  </b-form>
                </b-card-text>

                <template #modal-footer>
                  <div class="w-100">
                    <p class="float-left mb-0"></p>

                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      type="submit"
                      variant="outline-success"
                      class="mr-1 btn-icon"
                      @click.prevent="Tambah"
                    >
                      <feather-icon icon="SaveIcon" class="mr-25" />{{
                        label || "Tambah"
                      }}
                    </b-button>
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="outline-primary"
                      class="float-right btn-icon"
                      @click="Modal = false"
                    >
                      <feather-icon icon="LogOutIcon" class="mr-25" />Tutup
                    </b-button>
                  </div>
                </template>
              </b-modal>
            </b-col></b-row
          >
        </b-card-title>
        <b-row>
          <b-col md="3" sm="3" class="">
            <label>Pilih Tipe</label>
            <v-select
              class="select-size-sm w-full"
              label="value"
              v-model="filterTipe"
              @input="getData()"
              :clearable="true"
              :options="optTipe"
            />
          </b-col>
          <b-col md="3" sm="3" class="">
            <label>Pilih Status</label>
            <v-select
              class="select-size-sm w-full"
              label="value"
              v-model="filterStatus"
              @input="getData()"
              :clearable="true"
              :options="optStatus"
            />
          </b-col>
          <b-col md="3" sm="3" class="">
            <label>Metode Pembayaran</label>
            <v-select
              label="value"
              v-model="filterTipepay"
              class="select-size-sm w-full"
              @input="getData()"
              :clearable="true"
              :options="optTipepay"
            />
          </b-col>
        </b-row>
        <b-card-text class="blog-content-truncate">
          <b-row>
            <b-col md="3" sm="4" class="my-1">
              <b-form-group class="mb-0">
                <label class="d-inline-block text-sm-left mr-50"
                  >Per page</label
                >
                <b-form-select
                  id="perPageSelect"
                  v-model="perPage"
                  size="sm"
                  :options="pageOptions"
                  class="w-50"
                />
              </b-form-group>
            </b-col>
            <b-col md="4" sm="8" class="my-1">
              <b-form-group
                label="Sort"
                label-cols-sm="2"
                label-align-sm="right"
                label-size="sm"
                label-for="sortBySelect"
                class="mb-0"
              >
                <b-input-group size="sm">
                  <b-form-select
                    id="sortBySelect"
                    v-model="sortBy"
                    :options="sortOptions"
                    class="w-50"
                  >
                    <template v-slot:first>
                      <option value="">-- none --</option>
                    </template>
                  </b-form-select>
                  <b-form-select
                    v-model="sortDesc"
                    size="sm"
                    :disabled="!sortBy"
                    class="w-30"
                  >
                    <option :value="false">Asc</option>
                    <option :value="true">Desc</option>
                  </b-form-select>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col md="5" class="my-1">
              <b-form-group label-for="filterInput" class="mb-0">
                <b-input-group size="sm">
                  <b-form-input
                    id="filterInput"
                    v-model="filter"
                    type="search"
                    placeholder="Search......"
                  />
                  <b-input-group-append>
                    <b-button :disabled="!filter" @click="filter = ''">
                      Clear
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-table
                striped
                small
                hover
                responsive
                :per-page="perPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
                :fields="fields"
                :items="transaksiData"
              >
                <!-- A virtual column -->
                <template #cell(index)="data">
                  {{ data.index + 1 }}
                </template>

                <!-- A custom formatted column -->
                <template #cell(action)="row">
                  <b-button
                    title="Detail"
                    variant="flat-secondary"
                    class="btn-icon rounded-circle"
                    :to="{ path: `transaksi/detail/${row.item.id}` }"
                  >
                    <feather-icon icon="EyeIcon"
                  /></b-button>
                </template>
                <!-- A custom formatted column -->
                <template #cell(created_at)="data">
                  {{ humanDate(data.item.created_at) }}
                </template>

                <template #cell(status)="data">
                  <b-badge
                    v-if="data.item.status == 'UNPAID'"
                    variant="warning"
                  >
                    {{ data.item.status }}
                  </b-badge>
                  <b-badge
                    v-else-if="data.item.status == 'PAID'"
                    variant="success"
                  >
                    {{ data.item.status }}
                  </b-badge>
                  <b-badge
                    v-else-if="data.item.status == 'CANCELED'"
                    variant="light-danger"
                  >
                    {{ data.item.status }}
                  </b-badge>
                  <b-badge
                    v-else-if="data.item.status == 'REFUND'"
                    variant="info"
                  >
                    {{ data.item.status }}
                  </b-badge>
                  <b-badge
                    v-else-if="data.item.status == 'EXPIRED'"
                    variant="secondary"
                  >
                    {{ data.item.status }}
                  </b-badge>
                  <b-badge
                    v-else-if="data.item.status == 'FAILED'"
                    variant="danger"
                  >
                    {{ data.item.status }}
                  </b-badge>
                  <b-badge v-else variant="secondary"> - </b-badge>
                </template>
                <template #cell(payment_type)="data">
                  <b-badge
                    v-if="data.item.payment_type == 'manual'"
                    variant="warning"
                  >
                    <!-- {{ data.item.payment_type }} -->
                    Manual
                  </b-badge>
                  <b-badge
                    v-else-if="data.item.payment_type == 'payment_gateway'"
                    variant="success"
                  >
                    <!-- {{ data.item.payment_type }} -->
                    Otomatis
                  </b-badge>
                  <b-badge v-else variant="secondary"> - </b-badge>
                </template>
                <template #cell(type)="data">
                  <b-badge v-if="data.item.type == 'course'" variant="success">
                    <!-- {{ data.item.type }} -->
                    <feather-icon icon="PackageIcon" class="mr-25" />Paket
                  </b-badge>
                  <b-badge v-else-if="data.item.type == 'ebook'" variant="info">
                    <!-- {{ data.item.type }} -->
                    <feather-icon icon="BookIcon" class="mr-25" />E-Book
                  </b-badge>
                  <b-badge
                    v-else-if="data.item.type == 'snbp'"
                    variant="warning"
                  >
                    <!-- {{ data.item.type }} -->
                    <feather-icon icon="FileIcon" class="mr-25" />SNBP
                  </b-badge>
                  <b-badge v-else variant="secondary"> - </b-badge>
                </template>

                <!-- A virtual composite column -->
                <template #cell(merchant_ref)="row">
                  <b-link
                    class="text-dark"
                    :to="{ path: `transaksi/detail/${row.item.id}` }"
                    >{{ row.item.merchant_ref }}</b-link
                  >
                </template>

                <!-- Optional default data cell scoped slot -->
                <template #cell()="data">
                  {{ data.value }}
                </template>
              </b-table>
              <b-pagination
                :total-rows="totalRows"
                v-model="currentPage"
                :per-page="perPage"
                align="center"
                size="sm"
                class="my-0"
              />
            </b-col>
          </b-row>
        </b-card-text>
      </b-card-body>
    </b-overlay>
  </b-card>
</template>

<script>
import vSelect from "vue-select";
import {
  BOverlay,
  BRow,
  BCol,
  BTable,
  BCard,
  BCardText,
  BCardTitle,
  BCardBody,
  BPagination,
  BProgress,
  BBadge,
  BButton,
  BModal,
  VBModal,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BInputGroup,
  BInputGroupAppend,
  BFormRadioGroup,
  VBTooltip,
  BLink,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BOverlay,
    BRow,
    BCol,
    BCard,
    BCardText,
    BCardTitle,
    BCardBody,
    BTable,
    BPagination,
    BProgress,
    BBadge,
    BButton,
    BModal,
    VBModal,
    BForm,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BInputGroup,
    BInputGroupAppend,
    BFormRadioGroup,
    VBTooltip,
    BLink,
    ToastificationContent,
    vSelect,
  },
  directives: {
    "b-modal": VBModal,
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      id: null,
      activeAction: null,
      label: "Simpan",
      //Data
      transaksiData: [],
      form: {
        id: null,
        name: null,
      },
      //Modal
      titleModal: null,
      Modal: false,
      ModalEdit: false,
      //Table
      fields: [
        { key: "index", label: "No" },
        { key: "merchant_ref", label: "Invoice", sortable: true },
        { key: "created_at", label: "Tanggal", sortable: true },
        { key: "customer_name", label: "Konsumen", sortable: true },
        { key: "status", label: "Status", sortable: true },
        { key: "type", label: "Tipe", sortable: true },
        { key: "payment_type", label: "Metode Pembayaran", sortable: true },
        { key: "action", label: "#" },
      ],
      selected: [],
      total: [],
      record: [],
      filterStatus: null,
      filterTipe: null,
      optTipe: [
        { id: "course", value: "Paket" },
        { id: "ebook", value: "E-Book" },
        { id: "snbp", value: "SNBP" },
      ],
      optStatus: [
        { id: "UNPAID", value: "UNPAID" },
        { id: "PAID", value: "PAID" },
        { id: "CANCELED", value: "CANCELED" },
        { id: "FAILED", value: "FAILED" },
        { id: "EXPIRED", value: "EXPIRED" },
        { id: "REFUND", value: "REFUND" },
      ],
      filterTipepay: null,
      optTipepay: [
        { id: "manual", value: "Manual" },
        { id: "payment_gateway", value: "Otomatis" },
      ],
      perPage: 10,
      pageOptions: [10, 30, 50],
      totalRows: 1,
      currentPage: 1,
      sortBy: "created_at",
      sortDesc: true,
      sortDirection: "asc",
      filter: "",
      filterOn: [],
      // filter: "Transaksi",
      search: null,
      idd: null,
      loading: false,
    };
  },
  watch: {
    perPage(value) {
      if (value) {
        this.getData();
      }
    },
    filter(keyword) {
      if (keyword.length > 2 || keyword.length == 0) {
        this.getData();
      }
    },
    currentPage(val) {
      if (val) {
        this.getData();
      }
    },
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    ModalShow() {
      this.id = null;
      this.activeAction = "tambah";
      this.titleModal = "Tambah Transaksi";
      this.Modal = true;
      // this.ModalEdit = false;
    },
    ModalUbah(item) {
      this.id = item.id;
      this.form = item;
      this.activeAction = "ubah";
      this.titleModal = "Ubah Transaksi";
      this.Modal = true;
      // this.ModalEdit = true;
    },
    ModalHapus(data) {
      this.$swal({
        title: "Anda Yakin?",
        text: "Data tidak bisa dikembalikan!",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "Batal",
        confirmButtonText: "Ya, Hapus!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          data.fungsi = 2; //soft delete
          this.$store
            .dispatch("transaksi/createUpdateDelete", [data])
            .then(() => {
              this.getData();
              this.displaySuccess({
                text: "Data berhasil dihapus",
              });
            })
            .catch((e) => {
              this.displayError(e);
              return false;
            });
          this.$swal({
            icon: "success",
            title: "Terhapus!",
            text: "Data berhasil dihapus.",
            // timer: 1000,
            allowOutsideClick: true,
            customClass: {
              confirmButton: "btn btn-success",
            },
            buttonsStyling: false,
          });
        } else if (result.dismiss === "cancel") {
          this.$swal({
            title: "Batal",
            text: "Data aman, kamu batal menghapus data :)",
            icon: "error",
            // timer: 1000,
            allowOutsideClick: true,
            customClass: {
              confirmButton: "btn btn-success",
            },
            buttonsStyling: false,
          });
        }
      });
    },
    pesanGagal() {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Notifikasi",
            icon: "CheckIcon",
            text: "Gagal menyimpan data, harap cek kembali data yang diisi",
            variant: "warning",
          },
        },
        {
          position: "bottom-right",
        }
      );
    },
    Tambah() {
      if (this.form.name == null || this.form.name == "") {
        this.pesanGagal();
        return false;
      }
      let payload = {
        name: this.form.name,
      };

      if (this.id) {
        payload.id = this.id;
      }

      this.$store
        .dispatch("transaksi/createUpdateDelete", [payload])
        .then(() => {
          if (this.activeAction == "tambah") {
            this.id = null;
          }
          this.form.name = null;
          this.Modal = false;
          // this.ModalEdit = false;
          this.getData();
          this.displaySuccess({
            text: "Transaksi berhasil di" + this.activeAction,
          });
        })
        .catch((e) => {
          this.displayError(e);
          return false;
        });
    },
    getData() {
      this.loading = true;
      const perPage = parseInt(this.perPage);
      const currentPage = (parseInt(this.currentPage) - 1) * perPage;
      let payload = {
        status: this.filterStatus != null ? this.filterStatus.id : null,
        payment_type: this.filterTipepay != null ? this.filterTipepay.id : null,
        type: this.filterTipe != null ? this.filterTipe.id : null,
        order: "desc",
        start: currentPage,
        length: this.perPage,
      };

      if (this.filter.length > 2) {
        payload.search = this.filter;
      }

      this.$store
        .dispatch("transaksi/index", payload)
        .then((response) => {
          this.loading = false;
          const { data, totalRecords } = response.data;
          let transaksiData = data;
          this.transaksiData = transaksiData;
          this.totalRows = totalRecords;
        })
        .catch((error) => {
          this.loading = false;
          this.$root.$emit("errorData", error);
        })
        .finally(() => {
          this.selected = [];
        });
    },
  },
  created() {
    this.getData();
  },
};
</script>
